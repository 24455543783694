<template lang="pug">
include ../../../configs/template.pug
div.w-100.d-flex.flex-wrap
  div.w-100
    +select('body.status_document', 'statusChoose("ProtocolDKK")', 'status', 'nameLang')
  div.w-100
    +select('body.decision', 'decisionsList', 'solution', 'nameLang')
  div.w-100.mt-3
    v-btn(@click="saveStatus" :loading="isLoading" color="success") {{ $t('save') }}
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  props: {
    protocol: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      body: {
        status_document: this.protocol.status_document,
        decision: this.protocol.decision
      },
      isLoading: false
    }
  },
  computed: {
    ...mapGetters(['statusChoose']),
    ...mapState({
      nameLang: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      decisionsList: state => state.directory.solutions
    })
  },
  methods: {
    ...mapActions(['updateProtocolsSQC']),

    async saveStatus () {
      this.isLoading = true
      const response = await this.updateProtocolsSQC({ ...this.$route.params, body: this.body })
      if (SUCCESS_CODE.includes(response?.code)) this.$notification.success('statusProtocolSQC')
      this.isLoading = false
    }
  }
}
</script>
